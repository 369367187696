@import url('https://fonts.googleapis.com/css2?family=Noto+Sans+KR:wght@100;300;400;500;700;900&family=Poppins:ital,wght@0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&family=Urbanist:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800&display=swap');

* {
  font-family: 'Poppins', sans-serif;
  font-family: 'Noto Sans KR', sans-serif;
}

img {
  max-width: 100%;
  max-height: 100%;
}

.btn-danger {
  background: linear-gradient(0deg, #A41C1C 0%, #D31313 100%);
}

.border-none {
  border: none !important;
  outline: none !important;
}


.mb-100 {
  margin-bottom: 100px;
}

.ml-auto {
  margin-left: auto;
}

.padding-bot-100 {
  padding-bottom: 100px;
}

.cursor {
  cursor: pointer;
}

a {
  text-decoration: none;
  color: inherit;
}

a:hover {
  text-decoration: none;
  color: inherit;
}

.width-fill {
  width: -webkit-fill-available;
}

li {
  list-style-type: none;
}

.text-black {
  color: #000000;
}

.color_black {
  color: #1A1A1A;
}

.bg-glue {
  background-color: #11165B;
}

.mt-100 {
  margin-top: 100px;
}

.mt-150 {
  margin-top: 150px;
}

.mt-200 {
  margin-top: 200px;
}

.mb-200 {
  margin-bottom: 200px;
}

.mb-150 {
  margin-bottom: 150px;
}

.mb-100 {
  margin-bottom: 100px;
}

.mb-80 {
  margin-bottom: 80px;
}

.mb-60 {
  margin-bottom: 60px;
}

.mb-50 {
  margin-bottom: 50px;
}

.mb-40 {
  margin-bottom: 40px;
}

.mb-25 {
  margin-bottom: 25px;
}

.mb-20 {
  margin-bottom: 20px;
}

.mb-30 {
  margin-bottom: 30px;
}

.mr-20 {
  margin-right: 20px;
}

.mr-15 {
  margin-right: 15px;
}

.pb-60 {
  padding-bottom: 60px;
}

.pb-200 {
  padding-bottom: 200px;
}

.pt-150 {
  padding-top: 150px;
}

.text-right {
  text-align: right;
}

section {
  padding: 100px 0;
  background-color: transparent;
}

.text-orange {
  color: #FF724C;
}

.bg-orange {
  background-color: #FF724C;
}

.bg-grey {
  background-color: #F3F3F5;
}

.font-weight-medium {
  font-weight: 500;
}

.text-ellipsis {
  text-overflow: ellipsis;
  overflow: hidden;
}

.opacity-0 {
  opacity: 0;
}

.opacity-1 {
  opacity: 1;
}

.fz18 {
  font-size: 18px;
}

.fz14 {
  font-size: 14px;
}

.fz12 {
  font-size: 12px;
}

.fz10 {
  font-size: 10px;
}

.bg-black {
  background-color: #111111 !important;
}

.w-fit-content {
  width: fit-content;
}

.z-index {
  z-index: 1;
}

.min-h-100vh {
  min-height: calc(100vh - 460px);
}

.section-title {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 500;
  font-size: 30px;
  line-height: 35px;
  text-align: center;

  color: #111111;
  margin-bottom: 45px;
  position: relative;
}

.section-title span {
  position: absolute;
  right: 0;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  letter-spacing: 0.1em;
  text-transform: uppercase;
  color: #FFFFFF;
  margin-top: 20px;

}




/* Navbar */

.navbar {
  padding: 0;
  position: sticky !important;
  background: #0F0F21 !important;
}

.navbar-nav .nav-link {
  color: #FFF;
  font-size: 20px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  cursor: pointer;
}

.navbar-nav .nav-link:focus, .navbar-nav .nav-link:hover {
  color: #f7f7f7 !important;

}

.navbar-dark .navbar-nav .nav-link {
  color: #FFF;
}

.header_registration {
  color: #FFF;
  font-size: 20px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  cursor: pointer;
  display: flex;
  align-items: center;
  gap: 12px;
}

.header_registration:hover {
  color: #fff;
  opacity: 0.9;
}

.nav-link {
  padding: 10px 30px !important;
  color: #fff;
  font-weight: 600;
}

.nav-link:hover {
  padding: 10px 30px !important;
  color: #f2f2f2;
  font-weight: 600;
}

.nav-item a {
  text-decoration: none;
  font-weight: 500;
}

.nav-item-active a {
  color: #FF724C;
  text-decoration: none;
  margin-right: 30px;
  font-size: 16px;
}

.box-shadow-none {
  box-shadow: none !important;
}

.nav-min-h-100vh {
  min-height: calc(100vh - 136px);
}

.download_images {
  position: fixed;
  right: 0;
  top: 50%;
  transform: translate(0, -50%);
  z-index: 1000;
}

.download_images a {
  display: block;
  margin: 30px 0;
  width: 250px;
  cursor: pointer;
}

.video_area {
  position: relative;
  justify-content: center;
}

.video_area video {
  position: absolute;
  justify-content: center;
  left: 0;
  right: 0;
  top: 15px;
}

/* Navbar end */
/* Home */

.card-button {
  color: #fff;
  border: 1px solid #fff;
  padding: 7px 13px;
  border-radius: 50px;
  align-items: center;
  justify-content: center;
  gap: 5px;
  display: flex;
}
.card-button:hover {
  color: #E11010;
  border: 1px solid #E11010;
  
}
.card-button-area{
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 15px;
}
.game-list-thum .thumnail img:hover {
  -webkit-animation: scale-up-center 0.8s forwards;
  animation: scale-up-center 0.8s forwards;
}

@-webkit-keyframes scale-up-center {
  0% {
    -webkit-transform: scale(1);
    transform: scale(1);
  }

  100% {
    -webkit-transform: scale(1.05);
    transform: scale(1.05);
  }
}

@keyframes scale-up-center {
  0% {
    -webkit-transform: scale(1);
    transform: scale(1);
  }

  100% {
    -webkit-transform: scale(1.05);
    transform: scale(1.05);
  }
}

.game-list-thum .card-title {
  background: linear-gradient(180deg, #D0B42F 17.6%, #F8F38E 51.56%, #8D5D1E 77.36%);
  text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  font-size: 24px;
  text-align: center;
}

.game-list-thum .card-text {
  color: #ffffff;
  text-align: center;
  font-size: 15px;
}

.card-img, .card-img-top {
  border-top-left-radius: 13px;
  border-top-right-radius: 13px;
}

.game-list-thum {
  position: relative;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  min-width: 0;
  word-wrap: break-word;
  background-color: #0F0F21;
  background-clip: border-box;
  border: none;
  border-radius: 13px;
  margin-top: 20px;
  height: 425px;
}

.game-list-thum:hover {
  transform: translateY(-5px);
  box-shadow: 0 26px 23px -6px rgba(255, 255, 255, 0.3);
}

.slick-prev.slick-disabled:before, .slick-next.slick-disabled:before {
  margin-left: -25px;
}

.slick-prev:before, .slick-next:before {
  font-size: 32px;
}

.main_banner {
  background: url(../images/home/main_page_bg.png) right bottom no-repeat, url(../images/background/npokerbg_002.png);
  background-repeat: no-repeat;
  background-size: cover;
  background-position: calc(50% - 0px) 25px, center;
  min-height: 1000px;
}

.home_banner_002 {
  background: url(../images/home/home_page_bg_2.png);
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  min-height: 1300px;
}

.home_banner_003 {
  background: url(../images/home/home_page_bg_3.png);
  background-repeat: no-repeat;
  background-size: cover;
  background-position: top;
  display: flex;
  align-items: center;
  height: 800px;
}

.banner_inner_bg h1, .home_banner_text h1 {
  color: #FFF;
  text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  font-size: 50px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  text-align: center;
}


.banner_inner_bg {
  margin-top: 550px;
}

.banner_inner_bg h1 span {
  background: linear-gradient(180deg, #D0B42F 17.6%, #F8F38E 51.56%, #8D5D1E 77.36%);
  text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);

  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.home_banner_text h1 span {
  background: linear-gradient(180deg, #D0B42F 17.6%, #F8F38E 51.56%, #8D5D1E 77.36%);
  text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);

  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}



@media (max-width: 992px) {
  .nav_content {
    display: block;
    background-color: transparent;
    padding: 20px;
    padding-right: 0;
  }

  .nav-item {
    display: block;
    margin: 10px 0;
  }

  .carousel_dots li {
    width: 20px;
  }

  .nav-container {
    background-color: transparent;
  }


  .w-md-50 {
    width: 100%;
  }
}

.loadingModal .modal-content {
  background-color: #D9D9D9;
  height: 500px;
}

/*loading*/
.lds-roller {
  display: inline-block;
  position: relative;
  width: 80px;
  height: 80px;
  margin: auto;
}

.lds-roller div {
  animation: lds-roller 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
  transform-origin: 40px 40px;
}

.lds-roller div:after {
  content: " ";
  display: block;
  position: absolute;
  width: 7px;
  height: 7px;
  border-radius: 50%;
  background: #fff;
  margin: -4px 0 0 -4px;
}

.lds-roller div:nth-child(1) {
  animation-delay: -0.036s;
}

.lds-roller div:nth-child(1):after {
  top: 63px;
  left: 63px;
}

.lds-roller div:nth-child(2) {
  animation-delay: -0.072s;
}

.lds-roller div:nth-child(2):after {
  top: 68px;
  left: 56px;
}

.lds-roller div:nth-child(3) {
  animation-delay: -0.108s;
}

.lds-roller div:nth-child(3):after {
  top: 71px;
  left: 48px;
}

.lds-roller div:nth-child(4) {
  animation-delay: -0.144s;
}

.lds-roller div:nth-child(4):after {
  top: 72px;
  left: 40px;
}

.lds-roller div:nth-child(5) {
  animation-delay: -0.18s;
}

.lds-roller div:nth-child(5):after {
  top: 71px;
  left: 32px;
}

.lds-roller div:nth-child(6) {
  animation-delay: -0.216s;
}

.lds-roller div:nth-child(6):after {
  top: 68px;
  left: 24px;
}

.lds-roller div:nth-child(7) {
  animation-delay: -0.252s;
}

.lds-roller div:nth-child(7):after {
  top: 63px;
  left: 17px;
}

.lds-roller div:nth-child(8) {
  animation-delay: -0.288s;
}

.lds-roller div:nth-child(8):after {
  top: 56px;
  left: 12px;
}

@keyframes lds-roller {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}


.gameFi_upper_dark_bg {

  background-image: url("../images/background/npokerbg_002.png");
  background-repeat: no-repeat;
  background-size: cover;
  width: 100%;
  height: 100%;
}



/* registration  */
.login_fail {
  text-align: center;
  font-size: 14px;
  background: #f1f1f1;
  padding: 12px 0;
  margin-bottom: 10px;
}

.login_fail h5 {
  color: red;
  font-weight: 500;
  margin-bottom: 3px;
}

.registration_page_top {
  text-align: center;
}

.registration_title {
  color: #FFF;
  text-shadow: 0px 3px 1px rgba(0, 0, 0, 0.75);
  margin: 40px 0;
  text-align: center;
  font-size: 35px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}


.terms_conditions {
  max-width: 700px;
  margin: auto;
}

.terms_conditions .form-group {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 15px;
  color: #FFF;
  font-size: 20px;
  font-style: normal;
  font-weight: 500;

  /* text-decoration-line: underline; */
}

.terms_conditions .form-group input {
  padding: 0;
  height: initial;
  width: initial;
  margin-bottom: 0;
  display: none;
  cursor: pointer;
}

.terms_conditions .form-group label {
  position: relative;
  cursor: pointer;
}

.terms_conditions .form-group label:before {
  content: '';
  -webkit-appearance: none;
  background-color: transparent;
  border: 2px solid #00e600;
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.05), inset 0px -15px 10px -12px rgba(0, 0, 0, 0.05);
  padding: 9px;
  display: inline-block;
  position: relative;
  vertical-align: middle;
  cursor: pointer;
  margin-right: 15px;
}

.terms_conditions .form-group input:checked+label:after {
  content: '';
  display: block;
  position: absolute;
  top: 7px;
  left: 8px;
  width: 6px;
  height: 14px;
  border: solid #00e600;
  border-width: 0 2px 2px 0;
  transform: rotate(45deg);
}

.terms_conditions .form-group a {
  color: #00FFF6;
  text-decoration-line: underline;
}

.terms_conditions .form-group .terms_open_modal {
  text-decoration-line: underline;
  cursor: pointer;
}

.modal-content {
  border-radius: 13px;

}

.terms_modal .modal-header {
  background-image: url(../images/registration/modal_header.png);
  background-size: cover;
  border-bottom: none;
  justify-content: center;
  color: #fff;
}

.terms_modal_btn {
  padding: 10px 70px;
}

.modal_inner_text {
  height: 400px;
  overflow: scroll;
}

.registration_page label {
  color: #FFF;
  text-shadow: 0px 3px 1px rgba(0, 0, 0, 0.75);
  font-size: 18px;
  font-weight: normal;
  margin-bottom: 0.2rem;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.registration_page .form-group {
  margin-bottom: 15px;
}

.change_password_page label {
  color: #FFF;
  text-shadow: 0px 3px 1px rgba(0, 0, 0, 0.75);
  font-size: 18px;
  font-weight: normal;
  margin-bottom: 0.2rem;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.change_password_page .form-group {
  margin-bottom: 15px;
}

.form_button {
  display: flex;
  width: 100%;
  gap: 10px;
}

.form_button button {
  width: 190px;
}

.check_btn {
  border-radius: 5px;
  background: linear-gradient(180deg, #D21313 0%, #AF1A1A 100%);
  border: none;
  color: #fff;
}

.enter_btn {
  border-radius: 5px;
  background: linear-gradient(0deg, #A41C1C 0%, #D31313 100%);
  width: 100%;
  height: 60px;
  color: #fff;
  font-size: 30px;
  font-style: normal;
  font-weight: 600;
  margin-top: 40px;
  border: none;
}

.alert_message {
  color: #FFF000;
  text-shadow: 0px 3px 1px rgba(0, 0, 0, 0.75);
  margin-top: 4px;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  display: none;
  transition: 0.3s;
}

.alert_text_002 {
  color: #FFF000;
  text-shadow: 0px 3px 1px rgba(0, 0, 0, 0.75);
  margin-top: 40px;
  font-size: 17px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  transition: 0.3s;
  text-align: center;
}

.pass_show {
  color: gray;
  font-size: 33px;
  position: absolute;
  right: 10px;
  top: 33px;
  cursor: pointer;
}

.flex_end {
  display: flex;
  justify-content: flex-end;
}

.forgot {
  color: white;
  text-decoration: underline;
  font-size: 17px;
}

.forgot:hover {
  color: #FF724C;
}

/* registration end */

/* forgot start */

.find_btn {
  border-radius: 5px;
  background: linear-gradient(0deg, #A41C1C 0%, #D31313 100%);
  width: 30%;
  height: 50px;
  color: #fff;
  font-size: 24px;
  font-style: normal;
  font-weight: 500;
  margin-top: 30px;
  border: none;
}


/* fotgot end */


/* tabs start */

.tabs {
  position: relative;
  -webkit-overflow-scrolling: touch;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  -webkit-box-align: stretch;
  -ms-flex-align: stretch;
  align-items: stretch;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  white-space: nowrap;
  margin-bottom: 20px;
}

.tabs ul {
  padding: 0;
  margin-bottom: 0;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  flex-wrap: wrap;
  -webkit-box-flex: 1;
  -ms-flex-positive: 1;
  flex-grow: 1;
  -ms-flex-negative: 0;
  flex-shrink: 0;
  -webkit-box-pack: start;
  -ms-flex-pack: start;

}

.tabs li {
  cursor: pointer;
  display: block;
}

.tabs span svg {
  font-size: 18px;
  margin-right: 8px;
}

.tabs span {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  width: 75px;
  padding-bottom: 3px;
  /* background: #000; */
  font-family: 'Urbanist';
  font-style: normal;
  font-weight: 500;
  font-size: 15px;
  color: #FFFFFF;
}

.tabs span.active {
  font-family: 'Urbanist';
  font-style: normal;
  font-size: 15px;
  color: #5142FC;
  border-bottom: 1px solid #5142FC;
}


/* tabs end  */


/* Footer  */
.footer {
  background: #111111;
  padding: 50px 0;
}



.footer ul li {
  font-family: 'Noto Sans KR';
  font-style: normal;
  font-weight: 500;
  font-size: 15px;
  line-height: 20px;
  color: #fff;
  padding: 0 40px;
  border-left: 1px solid #fff;

}

.footer ul li span {
  color: #E11010;

}

.footer ul li a:hover {
  color: #E11010;
}

.footer ul li:first-child {
  font-family: 'Noto Sans KR';
  font-style: normal;
  font-weight: 500;
  font-size: 15px;
  line-height: 20px;
  color: #fff;
  padding-left: 0;
  border-left: none;
}

.footer ul {
  padding-left: 0;
  display: flex;
  list-style: none;
  margin-bottom: 5px;
}

.footer .footer_margin {
  margin-bottom: 40px;
}

.input_group {
  display: flex;
}

.input_group input {
  width: 250px;
  height: 56px;
  background: #0D0D11;
  border: 1px solid #343444;
  border-radius: 10px 0px 0px 10px;
  outline: none;
  padding: 10px;
  color: #FFF;
}

.send_btn {
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 60px;
  height: 56px;
  background: #5142FC;
  border-radius: 0px 10px 10px 0px;
}

.copyright_text {
  color: #fff;
  margin-bottom: 30px;
}

/* Footer end  */

/* indiana scroll  */
.indiana-scroll-container {
  overflow: auto;
  cursor: grab;
  display: flex;
  padding: 0 100px 40px;

  transform: rotateX(180deg) !important;
  -ms-transform: rotateX(180deg) !important;
  /* IE 9 */
  -webkit-transform: rotateX(180deg) !important;
}

.news_box {
  transform: rotateX(180deg) !important;
  -ms-transform: rotateX(180deg) !important;
  /* IE 9 */
  -webkit-transform: rotateX(180deg) !important;
}

.indiana-scroll-container::-webkit-scrollbar-track {
  background-color: #EEEEEE !important;
  background-clip: padding-box;
}

.indiana-scroll-container::-webkit-scrollbar {
  height: 4px !important;
  width: 2px;
  background-color: #F5F5F5;
}

.indiana-scroll-container--hide-scrollbars {
  overflow: auto;
  -ms-overflow-style: auto;
  scrollbar-width: auto;
}

.indiana-scroll-container--hide-scrollbars::-webkit-scrollbar {
  display: block !important;
  height: 4px !important;
  width: 2px !important;
  background: #F5F5F5 !important;

}

.indiana-scroll-container::-webkit-scrollbar-thumb {
  height: 6px;
  width: 100px;
  border-radius: 10px;
  -webkit-box-shadow: inset 0 0 2px rgba(0, 0, 0, .3);
  background-color: #D62929;
}

.mobile_view {
  display: none;
}

.mobile_logo {
  display: none;
}

.download_images_mobile {
  display: none;
}

@media (max-width: 568px) {

  .game-list-thum{
    height: auto;
  }
  .download_images {
    display: none;
  }

  .download_images_mobile {
    display: flex;
    justify-content: space-between;
  }

  .download_images_mobile a {
    /* display: block; */
    margin-top: 70px;
    width: 115px;
    cursor: pointer;
  }

  .footer_logo img {
    width: 150px;
    margin-bottom: 20px;
  }

  .mobile_logo {
    display: block;
    margin: auto;
    margin-bottom: 15px;
  }

  .enter_btn {
    border-radius: 5px;
    background: linear-gradient(0deg, #A41C1C 0%, #D31313 100%);
    width: 100%;
    height: 50px;
    color: #fff;
    font-size: 24px;
    font-style: normal;
    font-weight: 500;
    margin-top: 30px;
    border: none;
  }

  .terms_conditions .form-group {
    font-size: 13px;
  }

  .registration_title {
    font-size: 25px;
  }

  .video_area {
    display: none;
  }

  .main_banner {
    background: url(../images/background/mobail_maing_bg.png);
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    /* background-position: calc(50% - 9px) 25px, center; */
    min-height: auto;
    height: 555px;

  }

  .copyright_text {
    color: grey;
    margin: 20px 0;

    font-size: 12px;
    text-align: center;
  }

  .dropdown-li {
    height: 100%;
    margin-top: 20px;
    margin-right: 20px;
    margin-left: 0;
    margin-bottom: 20px;
    width: 100%;
  }

  .navbar-dark .navbar-brand img {
    width: 50px;
  }

  .home_banner_text h1 {
    font-size: 18px;
  }

  .banner_inner_bg {
    margin-top: 350px;
  }

  .banner_inner_bg h1 {
    display: none;
  }

  .mobile_view {
    display: block;
  }

  .mobile_view h1 {
    background: linear-gradient(180deg, #D0B42F 17.6%, #F8F38E 51.56%, #8D5D1E 77.36%);
    text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    display: block;
    font-size: 50px;
  }

  .mobile_view p {
    color: wheat;
    margin-bottom: 0;
  }

  .home_banner_002 {
    background: url(../images/home/home_page_bg_2.png);
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    padding-bottom: 40px;
    min-height: 0;
  }

  .footer ul {
    display: block !important;
  }

  .footer ul li {
    padding: 0px !important;
    border: none !important;
  }

  .footer .footer_margin {
    margin-bottom: 0px;
  }

  .footer_logo {
    text-align: center;
  }

  .home_banner_003 {
    height: auto;
  }
}